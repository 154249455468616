



























import { debounceProcess } from "@/helpers/debounce";
import { useBank } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ListCompanyBankDto } from "@/models/interface/company-bank";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectCompanyBank extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<ListCompanyBankDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAll, toOptions } = useBank();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useBank();
    const params = new RequestQueryParams();
    params.search = searchBy({
      bankAccName: val,
      bankAccNumber: val,
      bankName: val,
    });

    this.fetchOptions(params);
  }

  findOption(value: string): Option<ListCompanyBankDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
