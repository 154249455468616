


























import { debounceProcess } from "@/helpers/debounce";
import { useCurrency } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingCurrencyResponseDTO } from "@/models/interface/settings.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectCurrency extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingCurrencyResponseDTO>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findList, toOptionsNew } = useCurrency();
    this.loading = true;
    findList(params)
      .then(res => {
        this.options = toOptionsNew(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useCurrency();
    const params = new RequestQueryParams();
    params.search = searchBy({ code: val });

    this.fetchOptions(params);
  }

  findOption(value: string): Option<AccountingCurrencyResponseDTO> | undefined {
    return this.options.find(e => value === e.value);
  }
}
