



























import { debounceProcess } from "@/helpers/debounce";
import { useContactData } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ListContactDataDTO } from "@/models/interface/ContactDataDto.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectInsurance extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<ListContactDataDTO>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAllInsurance, toSupplierOptions } = useContactData();
    this.loading = true;
    findAllInsurance(params)
      .then(res => {
        this.options = toSupplierOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({
      firstName: val,
      lastName: val,
    });

    this.fetchOptions(params);
  }

  findOption(value: string): Option<ListContactDataDTO> | undefined {
    return this.options.find(e => value === e.value);
  }
}
